import { Typography } from '@material-ui/core';
import { ICountsData } from '../helpers';


function TotalChart({ counts }: { counts: ICountsData }) {
  return (
    <div className="TotalChart">
      <Typography>Total Profiles</Typography>
      <Typography style={{fontSize: "9vw", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center"}}>
        {counts.total}
      </Typography>
    </div>
  );
}

export default TotalChart;
