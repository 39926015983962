import { Typography } from '@material-ui/core';
import { Pie } from 'react-chartjs-2';
import { ICountsData } from '../helpers';

function PiechartOccupation({ counts }: { counts: ICountsData }) {
  const data = {
    labels: [
      'Architecture & Engineering',
      'Arts',
      'Business',
      'Communications',
      'Community',
      'Education',
      'Science & Technology',
      'Installation, Repair & Maintenance',
      'Farming, Fishing & Forestry',
      'Government',
      'Health & Medicine',
      'Law * Public Policy',
      'Sales',
      'Other',
    ],
    datasets: [
      {
        data: counts.work,
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(175, 92, 102, 1)',
          'rgba(218, 112, 198, 1)',
          'rgba(133, 192, 75, 1)',
          'rgba(198, 181, 155, 1)',
          'rgba(187, 80, 80, 1)',
          'rgba(114, 152, 227, 1)',
          'rgba(217, 218, 158, 1)',
          'rgba(148, 109, 213, 1)',
        ],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    
  };

  return (
    <div className="PiechartOccupation">
      <Typography>Occupation</Typography>
      <Pie
        data={data}
        width={300}
        height={300}
        options={options}
      />
    </div>
  );
}

export default PiechartOccupation;
