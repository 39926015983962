// IMPORTING APIS
import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  useMediaQuery,
  Button,
  useScrollTrigger,
  Slide,
  Menu,
  MenuItem,
  Grid
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, logout } from '../../firebase';


// IMPORTING ICONS
import MenuIcon from "@material-ui/icons/Menu";

// LOCAL-STYLING
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  MuiAppBarColorPrimary: {
    backgroundColor: '#999'
  }
}));

function HideOnScroll(props: any) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction={"down"} in={!trigger}>
      {children}
    </Slide>
  );
}

const Header = (props: any) => {
  const [user] = useAuthState(auth);
  const classes = useStyles();
  const [anchor, setAnchor] = React.useState(null);

  const open = Boolean(anchor);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleMenu = (event: any) => {
    setAnchor(event.currentTarget);
  };
  function handleLogout() {
    logout();
  };

  return (
    <div className={classes.root}>
      <HideOnScroll {...props}>
          <AppBar style={{ background: '#424242' }}>
            <Toolbar>
              {isMobile ? (
                <Grid container>
                  <Grid item sm={9} xs={9}>
                    <Link to="/">
                      <div style={{padding: '10px', fontSize: '1rem'}}>
                        <Typography>κτίσμᾰ • (ktísma)</Typography>
                        <Typography className="definition">1. a human settlement, foundation</Typography>
                        <Typography className="definition">2. a created thing where nothing existed before</Typography>
                      </div>
                    </Link>
                  </Grid>
                  <Grid item sm={3} xs={3} >
                    <>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchor}
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "center"
                        }}
                        keepMounted={true}
                        transformOrigin={{
                          vertical: "center",
                          horizontal: "center"
                        }}
                        open={open}
                      >
                        <MenuItem
                          onClick={() => setAnchor(null)}
                          component={Link}
                          to="/"
                        >
                          <Typography variant="h6"> Data</Typography>
                        </MenuItem>


                        <MenuItem
                          onClick={() => setAnchor(null)}
                          component={Link}
                          to="/about"
                        >
                          <Typography variant="h6"> About </Typography>
                        </MenuItem>

                        {
                          user ? (
                            <>
                              <MenuItem
                                onClick={() => setAnchor(null)}
                                component={Link}
                                to="/profile"
                              >
                                <Typography variant="h6"> Profile</Typography>
                              </MenuItem>
                              <MenuItem
                                onClick={handleLogout}
                                component={Link}
                                to="#"
                              >
                                <Typography variant="h6"> Logout</Typography>
                              </MenuItem>
                            </>
                          ) : (

                            <MenuItem
                              onClick={() => setAnchor(null)}
                              component={Link}
                              to="/login"
                            >
                              <Typography variant="h6"> Login </Typography>
                            </MenuItem>
                          )
                        }
                      </Menu>
                      <Grid container justify="flex-end" alignItems="center" style={{height: "100%"}}>
                        <Grid item>
                        <IconButton
                          color="primary"
                          className={classes.menuButton}
                          edge="start"
                          aria-label="menu"
                          onClick={handleMenu}
                        >
                          <MenuIcon />
                        </IconButton>
                        </Grid>
                      </Grid>
                    </>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={1}>
                  <Grid item sm={8}>
                    <Link to="/">
                      <div style={{display: "flex", alignItems: "center", padding: "15px 0px 15px 0px" }}>
                          <img src="/images/ktisis.png" style={{width: "90px", float: "left"}} alt="The Goddess Ktisma"  />
                          <div style={{float: "right", paddingLeft: "10px"}}>
                            <Typography>κτίσμᾰ • (ktísma) n (genitive κτίσμᾰτος)</Typography>
                            <Typography className="definition">1. a human settlement, foundation</Typography>
                            <Typography className="definition">2. a created thing where nothing existed before</Typography>
                          </div>
                      </div>
                    </Link>
                  </Grid>
                  <Grid item sm={4} container  justify="flex-end" alignItems="center">
                      <div style={{ display: "flex" }}>
                          <Button
                            variant="text"
                            component={Link}
                            to="/"
                            color="default"
                          >

                            Home
                          </Button>
                          <Button
                            variant="text"
                            component={Link}
                            to="/about"
                            color="default"
                          >
                            About
                          </Button>
                          {
                            user ? (
                              <>
                                <Button
                                  variant="text"
                                  component={Link}
                                  to="/profile"
                                  color="default"
                                >
                                  Profile
                                </Button>
                                <Button 
                                  variant="text"
                                  component={Link}
                                  onClick={handleLogout}
                                  to="#"
                                  color="default"
                                >
                                  Logout
                                </Button>
                              </>
                            ) : (
                              <Button
                                variant="text"
                                component={Link}
                                to="/login"
                                color="default"
                              >
                                Login
                              </Button>
                            )
                          }
                          <IconButton
                            color="primary"
                            className={classes.menuButton}
                            edge="start"
                            aria-label="menu"
                            onClick={handleMenu}
                          >
                            <MenuIcon />
                          </IconButton>                  
                      </div>
                    </Grid>
                  </Grid>
                  )
                }  
            </Toolbar>
          </AppBar>
      </HideOnScroll>
    </div>
  );
};

export default Header;
