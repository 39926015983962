import { Typography } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { ICountsData } from '../helpers';

function BarchartNetworth({ counts }: { counts: ICountsData }) {
  const data = {
    labels: ['<$10K', '$10K - $100K', '$100K - $500K', '$500K - $1M', '$1M - $5M', '$5M - $10M', '$10M - $50M', '$50M - $100M', '$100M - $500M', '$500M - $1B', '$1B - $10B', '$10B+'],
    datasets: [
      {
        data: counts.networth,
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(175, 92, 102, 1)',
          'rgba(218, 112, 198, 1)',
          'rgba(133, 192, 75, 1)',
          'rgba(198, 181, 155, 1)',
          'rgba(187, 80, 80, 1)',
          'rgba(114, 152, 227, 1)',
        ],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: 'white',
        }
      },
      y: {
        ticks: {
          color: 'white',
        }
      }
    } 
  };

  return (
    <div className="BarchartNetworth">
      <Typography>Networth</Typography>
      <Bar
        data={data}
        width={300}
        height={300}
        options={options}
      />
    </div>
  );
}

export default BarchartNetworth;
