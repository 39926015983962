import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, calculateCounts, getProfile, updateProfile } from '../firebase';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {
  Button,
  Grid,
  Typography,
  Paper,
  TextField,
  FormGroup,
  MenuItem,
  Slider,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';
import {
  Country,
  Gender,
  Age,
  Education,
  Networth,
  Marital,
  Work,
  Destination,
  Duration,
  Skillsets,
  Income,
  Reason,
  Financing,
} from '../helpers';
import { useEffect } from 'react';

const useStyles = makeStyles({
  formGroup: {
    margin: '1rem 0',
  },
  panel: {
    padding: '1rem',
  },
  labelText: {
    fontSize: 16,
    padding: '1rem 0 0 0',
  },
  helperText: {
    fontSize: 14,
    padding: '1rem 0',
  },
  loading: {
    color: '#A9A9A9',
    marginRight: '8px',
  },
});

function Profile() {
  const [user, loading, error] = useAuthState(auth);
  const classes = useStyles();
  const [profile, setProfile] = React.useState<any>(null);
  const [profileLoading, setProfileLoading] = React.useState(true);
  const [profileSaving, setProfileSaving] = React.useState(false);
  const [name, setName] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [city, setCity] = React.useState('');
  const [state, setState] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [age, setAge] = React.useState('');
  const [gender, setGender] = React.useState('');
  const [genderSpecific, setGenderSpecific] = React.useState('');
  const [education, setEducation] = React.useState('');
  const [work, setWork] = React.useState('');
  const [networth, setNetworth] = React.useState('');
  const [race, setRace] = React.useState('');
  const [ethnicity, setEthnicity] = React.useState('');
  const [marital, setMarital] = React.useState('');
  const [destination, setDestination] = React.useState('');
  const [destinationSpecific, setDestinationSpecific] = React.useState('');
  const [duration, setDuration] = React.useState('');
  const [skillsets, setSkillsets] = React.useState('');
  const [skillsetsSpecific, setSkillsetsSpecific] = React.useState('');
  const [income, setIncome] = React.useState('');
  const [reason, setReason] = React.useState('');
  const [reasonSpecific, setReasonSpecific] = React.useState('');
  const [financing, setFinancing] = React.useState('');
  const [financingSpecific, setFinancingSpecific] = React.useState('');
  const [risk, setRisk] = React.useState(5);

  // get profile
  useEffect(() => {
    if (user && user.email && !profile) {
      getProfile(user).then((profile) => {
        if (profile && profile.profileData && profile.userData) {
          setProfile(profile);
          setName(profile.userData.name);
          setEmail(profile.userData.email);
          setAddress(profile.profileData.address);
          setCity(profile.profileData.city);
          setState(profile.profileData.state);
          setCountry(profile.profileData.country);
          setPhone(profile.profileData.phone);
          setAge(profile.profileData.age);
          setGender(profile.profileData.gender);
          setGenderSpecific(profile.profileData.genderSpecific);
          setEducation(profile.profileData.education);
          setWork(profile.profileData.work);
          setNetworth(profile.profileData.networth);
          setRace(profile.profileData.race);
          setEthnicity(profile.profileData.ethnicity);
          setMarital(profile.profileData.marital);
          setDestination(profile.profileData.destination);
          setDestinationSpecific(profile.profileData.destinationSpecific);
          setDuration(profile.profileData.duration);
          setSkillsets(profile.profileData.skillsets);
          setSkillsetsSpecific(profile.profileData.skillsetsSpecific);
          setIncome(profile.profileData.income);
          setReason(profile.profileData.reason);
          setReasonSpecific(profile.profileData.reasonSpecific);
          setFinancing(profile.profileData.financing);
          setFinancingSpecific(profile.profileData.financingSpecific);
          setRisk(profile.profileData.risk);
          setProfileLoading(false);
        }
      });
    }
  }, [user, profile, name, email]);

  function handleChangeRisk(event: any, value: any) {
    setRisk(value);
  }

  function handleChange(event: any) {
    const { value, name } = event.target;

    switch(name) {
      case 'name':
        setName(value);
        break;
      case 'address':
        setAddress(value);
        break;
      case 'city':
        setCity(value);
        break;
      case 'state':
        setState(value);
        break;
      case 'country':
        setCountry(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'phone':
        setPhone(value);
        break;
      case 'age':
        setAge(value);
        break;
      case 'gender':
        setGender(value);
        break;
      case 'genderSpecific':
        setGenderSpecific(value);
        break;
      case 'education':
        setEducation(value);
        break;
      case 'work':
        setWork(value);
        break;
      case 'networth':
        setNetworth(value);
        break;
      case 'race':
        setRace(value);
        break;
      case 'ethnicity':
        setEthnicity(value);
        break;
      case 'marital':
        setMarital(value);
        break;
      case 'destination':
        setDestination(value);
        break;
      case 'duration':
        setDuration(value);
        break;
      case 'destinationSpecific':
        setDestinationSpecific(value);
        break;
      case 'skillsets':
        setSkillsets(value);
        break;
      case 'skillsetsSpecific':
        setSkillsetsSpecific(value);
        break;
      case 'income':
        setIncome(value);
        break;
      case 'reason':
        setReason(value);
        break;
      case 'reasonSpecific':
        setReasonSpecific(value);
        break;
      case 'financing':
        setFinancing(value);
        break;
      case 'financingSpecific':
        setFinancingSpecific(value);
        break;
      default:
        break;
    }
  }

  function handleSubmit() {
    setProfileSaving(true);
    updateProfile(user, name, {
      address,
      city,
      state,
      country,
      phone,
      age,
      gender,
      genderSpecific,
      education,
      work,
      networth,
      race,
      ethnicity,
      marital,
      destination,
      destinationSpecific,
      duration,
      skillsets,
      skillsetsSpecific,
      income,
      reason,
      reasonSpecific,
      financing,
      financingSpecific,
      risk,
    }).finally(() => {
      setProfileSaving(false);
      calculateCounts();
    });
  }

  // only render if we have the user
  if (user && !loading && !error) {
    // profile loading
    if (profileLoading) {
      return (
        <div className="Profile">
          <LinearProgress color="primary" />
        </div>
      );
    }

    return (
      <div className="Profile">
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Paper className={classes.panel}>
              <FormGroup className={classes.formGroup}>
                <TextField label="Email" name="email" value={email} onChange={handleChange} disabled fullWidth />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="Full Name" name="name" value={name} onChange={handleChange} fullWidth />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="Street Address" name="address" value={address} onChange={handleChange} fullWidth />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="City" name="city" value={city} onChange={handleChange} fullWidth />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="State/Province" name="state" value={state} onChange={handleChange} fullWidth />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="Country" name="country" value={country} onChange={handleChange} select fullWidth>
                  {
                    Object.keys(Country).map((key, i) => <MenuItem key={key} value={key}>{Object.values(Country)[i]}</MenuItem>)
                  }
                </TextField>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="Phone" name="phone" value={phone} onChange={handleChange} fullWidth />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="Age" name="age" value={age} onChange={handleChange} select fullWidth>
                  {
                    Object.keys(Age).map((key, i) => <MenuItem key={key} value={key}>{Object.values(Age)[i]}</MenuItem>)
                  }
                </TextField>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="Gender" name="gender" value={gender} onChange={handleChange} select fullWidth>
                  {
                    Object.keys(Gender).map((key, i) => <MenuItem key={key} value={key}>{Object.values(Gender)[i]}</MenuItem>)
                  }
                </TextField>
                {gender === 'PleaseSpecify' ? (
                  <TextField name="genderSpecific" value={genderSpecific} onChange={handleChange} fullWidth />
                ) : null}
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="Highest Level of Education" name="education" value={education} onChange={handleChange} select fullWidth>
                  {
                    Object.keys(Education).map((key, i) => <MenuItem key={key} value={key}>{Object.values(Education)[i]}</MenuItem>)
                  }
                </TextField>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="General Field of Work" name="work" value={work} onChange={handleChange} select fullWidth>
                  {
                    Object.keys(Work).map((key, i) => <MenuItem key={key} value={key}>{Object.values(Work)[i]}</MenuItem>)
                  }
                </TextField>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="Net Worth" name="networth" value={networth} onChange={handleChange} select fullWidth>
                  {
                    Object.keys(Networth).map((key, i) => <MenuItem key={key} value={key}>{Object.values(Networth)[i]}</MenuItem>)
                  }
                </TextField>
              </FormGroup>
            </Paper>
          </Grid>
          <Grid item md={6} xs={12}>
            <Paper className={classes.panel}>
              <FormGroup className={classes.formGroup}>
                <TextField label="Race" name="race" value={race} onChange={handleChange} fullWidth />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="Ethnicity" name="ethnicity" value={ethnicity} onChange={handleChange} fullWidth />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="Marital Status" name="marital" value={marital} onChange={handleChange} select fullWidth>
                  {
                    Object.keys(Marital).map((key, i) => <MenuItem key={key} value={key}>{Object.values(Marital)[i]}</MenuItem>)
                  }
                </TextField>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="Destination" name="destination" value={destination} onChange={handleChange} select fullWidth>
                  {
                    Object.keys(Destination).map((key, i) => <MenuItem key={key} value={key}>{Object.values(Destination)[i]}</MenuItem>)
                  }
                </TextField>
                {destination === 'PleaseSpecify' ? (
                  <TextField name="destinationSpecific" value={destinationSpecific} onChange={handleChange} fullWidth />
                ) : null}
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="Duration" name="duration" value={duration} onChange={handleChange} select fullWidth>
                  {
                    Object.keys(Duration).map((key, i) => <MenuItem key={key} value={key}>{Object.values(Duration)[i]}</MenuItem>)
                  }
                </TextField>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="Useful Skillsets" name="skillsets" value={skillsets} onChange={handleChange} select fullWidth>
                  {
                    Object.keys(Skillsets).map((key, i) => <MenuItem key={key} value={key}>{Object.values(Skillsets)[i]}</MenuItem>)
                  }
                </TextField>
                {skillsets === 'PleaseSpecify' ? (
                  <TextField name="skillsetsSpecific" value={skillsetsSpecific} onChange={handleChange} fullWidth />
                ) : null}
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="Yearly Income" name="income" value={income} onChange={handleChange} select fullWidth>
                  {
                    Object.keys(Income).map((key, i) => <MenuItem key={key} value={key}>{Object.values(Income)[i]}</MenuItem>)
                  }
                </TextField>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="Reason Category" name="reason" value={reason} onChange={handleChange} select fullWidth>
                  {
                    Object.keys(Reason).map((key, i) => <MenuItem key={key} value={key}>{Object.values(Reason)[i]}</MenuItem>)
                  }
                </TextField>
                {reason === 'PleaseSpecify' ? (
                  <TextField name="reasonSpecific" value={reasonSpecific} onChange={handleChange} fullWidth />
                ) : null}
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <TextField label="Financing Options" name="financing" value={financing} onChange={handleChange} select fullWidth>
                  {
                    Object.keys(Financing).map((key, i) => <MenuItem key={key} value={key}>{Object.values(Financing)[i]}</MenuItem>)
                  }
                </TextField>
                {financing === 'PleaseSpecify' ? (
                  <TextField name="financingSpecific" value={financingSpecific} onChange={handleChange} fullWidth />
                ) : null}
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Typography id="risk-slider" className={classes.labelText}>
                  Acceptable Risk
                </Typography>
                <Typography className={classes.helperText}>
                  0 - 10 where 0 is absolutely guaranteed to be safe and 10 where serious injury or death are highly likely. 
                </Typography>
                <Slider
                  value={risk}
                  aria-labelledby="risk-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={0}
                  max={10}
                  onChange={handleChangeRisk}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  {
                    profileSaving ? (
                      <CircularProgress className={classes.loading} size={24} />
                    ) : "Submit"
                  }
                </Button>
              </FormGroup>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className="Profile">
      Please <Link to="/login">login</Link> first before updating your profile.
    </div>
  );
}

export default Profile;
