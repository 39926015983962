import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  Button,
  Grid,
  Paper,
  TextField,
  FormGroup,
  Typography,
} from '@material-ui/core';
import { signinWithEmailAndPassword, signinWithGithub, signinWithGoogle, signupWithEmailAndPassword } from '../firebase';
import { uhOh } from '../helpers';

const useStyles = makeStyles({
  formGroup: {
    margin: '1rem 0',
  },
  panel: {
    padding: '1rem',
  },
  labelText: {
    fontSize: 16,
    padding: '1rem 0 0 0',
  },
  helperText: {
    fontSize: 14,
    padding: '1rem 0',
  },
});

function Login() {
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [name, setName] = React.useState('');
  const [signupEmail, setSignupEmail] = React.useState('');
  const [signupPassword, setSignupPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const validEmail = validateEmail(email);
  const validSignupEmail = validateEmail(signupEmail);
  const disableSignin = !(
    validEmail &&
    (email.length > 0) &&
    (password.length > 0)
  );
  const disableSignup = !(
    validSignupEmail &&
    (signupEmail.length > 0) &&
    (signupPassword.length > 0) &&
    (name.length > 0) &&
    (signupPassword.length >= 6) &&
    (signupPassword === confirmPassword)
  );

  function handleChange(event: any) {
    const { value, name } = event.target;

    switch(name) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'name':
        setName(value);
        break;
      case 'signupEmail':
        setSignupEmail(value);
        break;
      case 'signupPassword':
        setSignupPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  }

  function validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  function handleLogin() {
    signinWithEmailAndPassword(email, password).then(() => {
      history.push('/profile');
    }).catch((err) => {
      uhOh('We encountered the following error code: ' + err.code);
    });
  }

  function handleSignup() {
    signupWithEmailAndPassword(name, signupEmail, signupPassword).then(() => {
      history.push('/profile');
    }).catch((err) => {
      if (err.code === 'auth/email-already-in-use') {
        uhOh('An account already exists with this email!');
      } else {
        uhOh('We encountered the following error code: ' + err.code);
      }
    });
  }

  function handleGoogleSignin() {
    signinWithGoogle().then(() => {
      history.push('/profile');
    }).catch((err) => {
      uhOh('We encountered the following error code: ' + err.code);
    });
  }

  function handleGithubSignin() {
    signinWithGithub().then(() => {
      history.push('/profile');
    }).catch((err) => {
      uhOh('We encountered the following error code: ' + err.code);
    });
  }

  function handleKeyDownLogin(e: any) {
    if ((e.key === 'Enter') && !disableSignin) {
      handleLogin();
    }
  }

  function handleKeyDownSignup(e: any) {
    if ((e.key === 'Enter') && !disableSignup){
      handleSignup();
    }
  }

  if (user && !loading && !error) {
    return (
      <div className="Login">
        You are already logged in! Are you looking for your <Link to="/profile">profile</Link>?
      </div>
    )
  }

  return (
    <div className="Login">
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <Paper className={classes.panel}>
            <Typography variant="h4">Sign In</Typography>
            <FormGroup className={classes.formGroup}>
              <TextField label="Email" name="email" value={email} onChange={handleChange} onKeyDown={handleKeyDownLogin} fullWidth />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <TextField label="Password" name="password" type="password" value={password} onChange={handleChange} onKeyDown={handleKeyDownLogin} fullWidth />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <Button variant="contained" color="primary" onClick={handleLogin} disabled={disableSignin}>
                Login
              </Button>
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <Button variant="contained" color="secondary" onClick={handleGoogleSignin}>
                Login with Google
              </Button>
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <Button variant="contained" color="secondary" onClick={handleGithubSignin}>
                Login with GitHub
              </Button>
            </FormGroup>
          </Paper>
        </Grid>
        <Grid item md={6} xs={12}>
          <Paper className={classes.panel}>
            <Typography variant="h4">Sign Up</Typography>
            <FormGroup className={classes.formGroup}>
              <TextField label="Full Name" name="name" value={name} onChange={handleChange} onKeyDown={handleKeyDownSignup} fullWidth />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <TextField label="Email" name="signupEmail" value={signupEmail} onChange={handleChange} onKeyDown={handleKeyDownSignup} fullWidth />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <TextField label="Password" name="signupPassword" type="password" value={signupPassword} onChange={handleChange} onKeyDown={handleKeyDownSignup} fullWidth />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <TextField label="Confirm Password" name="confirmPassword" type="password" value={confirmPassword} onChange={handleChange} onKeyDown={handleKeyDownSignup} fullWidth />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <Button variant="contained" color="primary" onClick={handleSignup} disabled={disableSignup}>
                Signup
              </Button>
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <Button variant="contained" color="secondary" onClick={handleGoogleSignin}>
                Signup with Google
              </Button>
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <Button variant="contained" color="secondary" onClick={handleGithubSignin}>
                Signup with GitHub
              </Button>
            </FormGroup>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;
