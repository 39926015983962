import { Typography } from '@material-ui/core';
import { Pie } from 'react-chartjs-2';
import { ICountsData } from '../helpers';

function PiechartRisk({ counts }: { counts: ICountsData }) {
  const data = {
    labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    datasets: [
      {
        data: counts.risk,
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(175, 92, 102, 1)',
          'rgba(218, 112, 198, 1)',
          'rgba(55, 19, 6, 1)',
          'rgba(102, 42, 182, 1)',
        ],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
  };

  return (
    <div className="PiechartRisk">
      <Typography>Risk</Typography>
      <Pie
        data={data}
        width={300}
        height={300}
        options={options}
      />
      <Typography align="center">(0 = No Risk, 10 = Certain Death)</Typography>
    </div>
  );
}

export default PiechartRisk;
