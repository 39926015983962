import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  // CardActions,
  // CardMedia,
  // Button,
  // Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    height: "100%"
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
});

export default function DataCard({ chart }: any) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        {chart}
      </CardContent>
    </Card>
  );
}
