import { Typography, Paper, Card, CardMedia, Grid } from '@material-ui/core';

function About() {
  return (
    <Paper className="About">
    <Grid container spacing={2}>
      <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>About</Typography>
            <Typography gutterBottom>There are people who look up at the night sky and see more than just planets, stars, and galaxies. They see a place where the human experience can go beyond the limits of our home planet. A place where human beings can live, work, play, explore, struggle, love, and yes, even eventually die. Some feel it so strongly that they can easily leave the Earth behind and never come back. Others still feel the same feeling but prefer to only visit. Each person has subtle differences in the what, how, and where that drives them.</Typography>
            <Typography gutterBottom>If you are one of those people then this is the place where you can voice that feeling, safely and privately add your voice to others, and help the world understand that for us, the future of humanity is Out There.</Typography>
            <Typography gutterBottom>To add your voice simply create an account and then fill in your profile. For now, we are simply aggregating the data as a resource for policymakers, companies, and the press to understand our community. Eventually, we will add some light social features to help people communicate within the community.</Typography>
          <Typography gutterBottom variant="h4">Goals</Typography>
            <Typography gutterBottom>There are three goals for this website and for the data that we collect. The first is to provide future space settlers a way to communicate their personal desire for space travel to the world at large, to let people know that we exist. The second is to act as a locaiton where companies, media, politicians, and market researchers can go to really see who we are and what we want. The third is to provide a place where we can talk to each other, find the latest space settlement news, and potentially engage in our own advocacy.</Typography>
          <Typography gutterBottom variant="h4">Privacy</Typography>
            <Typography gutterBottom>We will never share any data that you don't explicitly want shared. We will eventually add a feature where you can publish limited parts of your profile publicly in case you want to contacted directly. We will never market products to you, send you spam, or do anything else other than store you profile securely using Google Firebase and use that information to create the aggregate market data you see on the front page. All new features for sharing data with anyone will always be opt-in.</Typography>
          <Typography gutterBottom variant="h4">Who</Typography>
      </Grid>
      <Grid item xs={1}>
        <Card >
          <CardMedia
            component="img"
            height="140"
            width="140"
            image="/images/mealling-light.png"
            alt="Michael Mealling"
          />
        </Card>
      </Grid>
    <Grid item xs={11}>
      <Typography gutterBottom variant="h4">Michael Mealling</Typography>
            <Typography gutterBottom>Ktisma is conceived by Michael Mealling and built with the help of some friends. Michael is the President of the Moon Society, a Board member with the Alliance for Space Development, and a General Partner at Starbridge Venture Capital. You can contact him directly at <a href="mailto:mmealling@gmail.com">mmealling@gmail.com</a>.</Typography>
    </Grid>
  </Grid>  
      </Paper>
  );
}

export default About;
