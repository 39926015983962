import './NewsHero.css';
import { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';

import { Typography,
         Grid,
         Card,
         CardMedia,
         CardActionArea,
         CardContent } from '@material-ui/core';
import { getNews } from '../../firebase';
import { INewsItem } from '../helpers';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';



export default function NewHero() {
  const [items, setItems] = useState<INewsItem[]>();

  useEffect(() => {
    if (!items) {
      getNews().then(async (newsItems) => {
        var storage = getStorage();
        for(let i=0, len = newsItems.length; i<len;i++) {
          if(newsItems[i].hasOwnProperty('image') && newsItems[i].image) {
            await getDownloadURL(ref(storage, newsItems[i].image))
              .then((url) => {
                newsItems[i].imageURL = url;
              })
              .catch((error) => {
                console.log(error)
                newsItems[i].imageURL = ""
              })
          } else {
            newsItems[i].imageURL="";
          }
        }
        setItems(newsItems);
      });
    }
  }, [items]);

  const news = items || [] as INewsItem[];
  
  return (
      <Carousel stopAutoPlayOnHover={true} interval={8000} timeout={1000} navButtonsAlwaysInvisible={true}>
          {
              news.map( (item, i) => <Item key={i} item={item} /> )
          }
      </Carousel>
  )
}

function Item(props: any)
{
    return (
      <Card raised>
        <CardActionArea href={props.item.link} target="_blank">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs sm={4}>
                  <Typography gutterBottom variant="h5" component="h2">
                      {props.item.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p"> 
                      {props.item.description}
                  </Typography>
                </Grid>
                <Grid item xs sm={8}>
                  <CardMedia component="img" height="200" image={props.item.imageURL} title={props.item.title} />
                </Grid>
              </Grid>
            </CardContent>           
        </CardActionArea>
      </Card>
    )
}
