import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics'
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  // collection,
  // query,
  // getDocs,
  // where,
  // connectFirestoreEmulator,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import md5 from 'md5';
import {
  getAuth,
  GoogleAuthProvider,
  GithubAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { 
  emptyProfileData,
  ICountsData,
  IProfileData,
} from './helpers';

// TODO: update this with REACT ENV vars
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY || 'AIzaSyDyd0Jb2u3z_9XMKpFm5u7LXA0dmDcPbVk',
  authDomain: process.env.REACT_APP_AUTH_DOMAIN || 'ktisma-dev.firebaseapp.com',
  databaseURL: process.env.REACT_APP_DATABASE_URL || 'https://ktisma-dev-default-rtdb.firebaseio.com',
  projectId: process.env.REACT_APP_PROJECT || 'ktisma-dev',
  storageBucket: process.env.REACT_APP_STORAGE || 'ktisma-dev.appspot.com',
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID || '711265280451',
  appId: process.env.REACT_APP_ID || '1:711265280451:web:d58e7e1362ec4bbbc75d76',
  measurementId: process.env.REACT_APP_MEASUREMENT_ID || 'G-91B4E7RJC9'
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'Application loading')
const functions = getFunctions(app);
const calculateCounts = httpsCallable(functions, 'calculateCounts');
const auth = getAuth();
const db = getFirestore();
// connectFirestoreEmulator(db, 'localhost', 8080);
// const usersRef = collection(db, 'users');
// const profilesRef = collection(db, 'profiles');
const googleProvider = new GoogleAuthProvider();
const githubProvider = new GithubAuthProvider();

const signinWithGoogle = async () => {
  try {
    await signInWithRedirect(auth, googleProvider);
    // const user = res.user;
    // if (user.email) {
    //   const userId = md5(user.email).toUpperCase();
    //   // const q = await getDocs(query(usersRef, where('email', '==', user.email)));
    //   // if (q.docs.length === 0)
    //   await setDoc(doc(db, 'users', userId), {
    //     uid: user.uid,
    //     name: user.displayName,
    //     authProvider: 'google',
    //     email: user.email,
    //   });
    // } else {
    //   throw new Error('Missing user email!');
    // }
  } catch (err) {
    throw err;
  }
};

const signinWithGithub = async () => {
  try {
    await signInWithRedirect(auth, githubProvider);
    // const user = res.user;
    // if (user.email) {
    //   const userId = md5(user.email).toUpperCase();
    //   // const q = await getDocs(query(usersRef, where('email', '==', user.email)));
    //   // if (q.docs.length === 0)
    //   await setDoc(doc(db, 'users', userId), {
    //     uid: user.uid,
    //     name: user.displayName,
    //     authProvider: 'github',
    //     email: user.email,
    //   });
    // } else {
    //   throw new Error('Missing user email!');
    // }
  } catch (err) {
    throw err;
  }
};

const signinWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    throw err;
  }
};

const signupWithEmailAndPassword = async (name: string, email: string, password: string) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    if (user.email) {
      const userId = md5(user.email).toUpperCase();
      await setDoc(doc(db, 'users', userId), {
        uid: user.uid,
        name,
        authProvider: 'local',
        email,
      });
    } else {
      throw new Error('Missing user email!');
    }
  } catch (err) {
    throw err;
  }
};

const getNews = async () => {
  const items = await getDocs(collection(db, "news"));
  let newsItems = []
  items.forEach((doc) => {
    newsItems.push(doc.data())
  })
  return newsItems as INewsItem[];
}

const getProfile = async (user: any) => {
  try {
    if (user && user.email) {
      const userId = md5(user.email).toUpperCase();
      const profileDoc = await getDoc(doc(db, 'profiles', userId));
      const profileData = (profileDoc.data() || emptyProfileData) as IProfileData;
      const userDoc = await getDoc(doc(db, 'users', userId));
      const userData = userDoc.data();
      return {
        profileData,
        userData,
      };
    } else {
      throw new Error('Missing user id');
    }
  } catch (err) {
    throw err;
  }
}

const updateProfile = async (user: any, name: string, profileData: IProfileData) => {
  try {
    if (user && user.email) {
      const userId = md5(user.email).toUpperCase();
      await setDoc(doc(db, 'profiles', userId), profileData);
      await updateDoc(doc(db, 'users', userId), { name });
    } else {
      throw new Error('Missing user id');
    }
  } catch (err) {
    throw err;
  }
};

const getCounts = async () => {
  const countsDoc = await getDoc(doc(db, 'counts', 'all'));
  return countsDoc.data() as ICountsData;
}

const resetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert('Password reset link sent!');
  } catch (err) {
    throw err;
  }
};

const logout = () => {
  auth.signOut();
};

export {
  auth,
  db,
  getRedirectResult,
  signinWithGoogle,
  signinWithGithub,
  signinWithEmailAndPassword,
  signupWithEmailAndPassword,
  resetPassword,
  logout,
  getProfile,
  updateProfile,
  getCounts,
  getNews,
  calculateCounts,
};
