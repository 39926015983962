import Swal from 'sweetalert2';
import bg1 from './assets/images/bgs/bg1.jpg';
import bg2 from './assets/images/bgs/bg2.jpg';
import bg3 from './assets/images/bgs/bg3.jpg';
import bg4 from './assets/images/bgs/bg4.jpg';
import bg5 from './assets/images/bgs/bg5.jpg';
import bg6 from './assets/images/bgs/bg6.jpg';
import bg7 from './assets/images/bgs/bg7.jpg';

export const assets = [
  bg1,
  bg2,
  bg3,
  bg4,
  bg5,
  bg6,
  bg7,
];

export const emptyCountsData = {
  total: 0,
  age: [],
  destination: [],
  duration: [],
  education: [],
  financing: [],
  networth: [],
  reason: [],
  work: [],
  risk: [],
  country: [],
};

export const uhOh = (text: string) => {
  return Swal.fire('Uh oh!', text);
}

// interfaces and enums
export enum Country {
  AFG = 'Afghanistan',
  ALB = 'Albania',
  DZA = 'Algeria',
  ASM = 'American Samoa',
  AND = 'Andorra',
  AGO = 'Angola',
  AIA = 'Anguilla',
  ATA = 'Antarctica',
  ATG = 'Antigua and Barbuda',
  ARG = 'Argentina',
  ARM = 'Armenia',
  ABW = 'Aruba',
  AUS = 'Australia',
  AUT = 'Austria',
  AZE = 'Azerbaijan',
  BHS = 'Bahamas (the)',
  BHR = 'Bahrain',
  BGD = 'Bangladesh',
  BRB = 'Barbados',
  BLR = 'Belarus',
  BEL = 'Belgium',
  BLZ = 'Belize',
  BEN = 'Benin',
  BMU = 'Bermuda',
  BTN = 'Bhutan',
  BOL = 'Bolivia (Plurinational State of)',
  BES = 'Bonaire, Sint Eustatius and Saba',
  BIH = 'Bosnia and Herzegovina',
  BWA = 'Botswana',
  BVT = 'Bouvet Island',
  BRA = 'Brazil',
  IOT = 'British Indian Ocean Territory (the)',
  BRN = 'Brunei Darussalam',
  BGR = 'Bulgaria',
  BFA = 'Burkina Faso',
  BDI = 'Burundi',
  CPV = 'Cabo Verde',
  KHM = 'Cambodia',
  CMR = 'Cameroon',
  CAN = 'Canada',
  CYM = 'Cayman Islands (the)',
  CAF = 'Central African Republic (the)',
  TCD = 'Chad',
  CHL = 'Chile',
  CHN = 'China',
  CXR = 'Christmas Island',
  CCK = 'Cocos (Keeling) Islands (the)',
  COL = 'Colombia',
  COM = 'Comoros (the)',
  COD = 'Congo (the Democratic Republic of the)',
  COG = 'Congo (the)',
  COK = 'Cook Islands (the)',
  CRI = 'Costa Rica',
  HRV = 'Croatia',
  CUB = 'Cuba',
  CUW = 'Curaçao',
  CYP = 'Cyprus',
  CZE = 'Czechia',
  CIV = 'Côte d\'Ivoire',
  DNK = 'Denmark',
  DJI = 'Djibouti',
  DMA = 'Dominica',
  DOM = 'Dominican Republic (the)',
  ECU = 'Ecuador',
  EGY = 'Egypt',
  SLV = 'El Salvador',
  GNQ = 'Equatorial Guinea',
  ERI = 'Eritrea',
  EST = 'Estonia',
  SWZ = 'Eswatini',
  ETH = 'Ethiopia',
  FLK = 'Falkland Islands (the) [Malvinas]',
  FRO = 'Faroe Islands (the)',
  FJI = 'Fiji',
  FIN = 'Finland',
  FRA = 'France',
  GUF = 'French Guiana',
  PYF = 'French Polynesia',
  ATF = 'French Southern Territories (the)',
  GAB = 'Gabon',
  GMB = 'Gambia (the)',
  GEO = 'Georgia',
  DEU = 'Germany',
  GHA = 'Ghana',
  GIB = 'Gibraltar',
  GRC = 'Greece',
  GRL = 'Greenland',
  GRD = 'Grenada',
  GLP = 'Guadeloupe',
  GUM = 'Guam',
  GTM = 'Guatemala',
  GGY = 'Guernsey',
  GIN = 'Guinea',
  GNB = 'Guinea-Bissau',
  GUY = 'Guyana',
  HTI = 'Haiti',
  HMD = 'Heard Island and McDonald Islands',
  VAT = 'Holy See (the)',
  HND = 'Honduras',
  HKG = 'Hong Kong',
  HUN = 'Hungary',
  ISL = 'Iceland',
  IND = 'India',
  IDN = 'Indonesia',
  IRN = 'Iran (Islamic Republic of)',
  IRQ = 'Iraq',
  IRL = 'Ireland',
  IMN = 'Isle of Man',
  ISR = 'Israel',
  ITA = 'Italy',
  JAM = 'Jamaica',
  JPN = 'Japan',
  JEY = 'Jersey',
  JOR = 'Jordan',
  KAZ = 'Kazakhstan',
  KEN = 'Kenya',
  KIR = 'Kiribati',
  PRK = 'Korea (the Democratic People\'s Republic of)',
  KOR = 'Korea (the Republic of)',
  KWT = 'Kuwait',
  KGZ = 'Kyrgyzstan',
  LAO = 'Lao People\'s Democratic Republic (the)',
  LVA = 'Latvia',
  LBN = 'Lebanon',
  LSO = 'Lesotho',
  LBR = 'Liberia',
  LBY = 'Libya',
  LIE = 'Liechtenstein',
  LTU = 'Lithuania',
  LUX = 'Luxembourg',
  MAC = 'Macao',
  MDG = 'Madagascar',
  MWI = 'Malawi',
  MYS = 'Malaysia',
  MDV = 'Maldives',
  MLI = 'Mali',
  MLT = 'Malta',
  MHL = 'Marshall Islands (the)',
  MTQ = 'Martinique',
  MRT = 'Mauritania',
  MUS = 'Mauritius',
  MYT = 'Mayotte',
  MEX = 'Mexico',
  FSM = 'Micronesia (Federated States of)',
  MDA = 'Moldova (the Republic of)',
  MCO = 'Monaco',
  MNG = 'Mongolia',
  MNE = 'Montenegro',
  MSR = 'Montserrat',
  MAR = 'Morocco',
  MOZ = 'Mozambique',
  MMR = 'Myanmar',
  NAM = 'Namibia',
  NRU = 'Nauru',
  NPL = 'Nepal',
  NLD = 'Netherlands (the)',
  NCL = 'New Caledonia',
  NZL = 'New Zealand',
  NIC = 'Nicaragua',
  NER = 'Niger (the)',
  NGA = 'Nigeria',
  NIU = 'Niue',
  NFK = 'Norfolk Island',
  MNP = 'Northern Mariana Islands (the)',
  NOR = 'Norway',
  OMN = 'Oman',
  PAK = 'Pakistan',
  PLW = 'Palau',
  PSE = 'Palestine, State of',
  PAN = 'Panama',
  PNG = 'Papua New Guinea',
  PRY = 'Paraguay',
  PER = 'Peru',
  PHL = 'Philippines (the)',
  PCN = 'Pitcairn',
  POL = 'Poland',
  PRT = 'Portugal',
  PRI = 'Puerto Rico',
  QAT = 'Qatar',
  MKD = 'Republic of North Macedonia',
  ROU = 'Romania',
  RUS = 'Russian Federation (the)',
  RWA = 'Rwanda',
  REU = 'Réunion',
  BLM = 'Saint Barthélemy',
  SHN = 'Saint Helena, Ascension and Tristan da Cunha',
  KNA = 'Saint Kitts and Nevis',
  LCA = 'Saint Lucia',
  MAF = 'Saint Martin (French part)',
  SPM = 'Saint Pierre and Miquelon',
  VCT = 'Saint Vincent and the Grenadines',
  WSM = 'Samoa',
  SMR = 'San Marino',
  STP = 'Sao Tome and Principe',
  SAU = 'Saudi Arabia',
  SEN = 'Senegal',
  SRB = 'Serbia',
  SYC = 'Seychelles',
  SLE = 'Sierra Leone',
  SGP = 'Singapore',
  SXM = 'Sint Maarten (Dutch part)',
  SVK = 'Slovakia',
  SVN = 'Slovenia',
  SLB = 'Solomon Islands',
  SOM = 'Somalia',
  ZAF = 'South Africa',
  SGS = 'South Georgia and the South Sandwich Islands',
  SSD = 'South Sudan',
  ESP = 'Spain',
  LKA = 'Sri Lanka',
  SDN = 'Sudan (the)',
  SUR = 'Suriname',
  SJM = 'Svalbard and Jan Mayen',
  SWE = 'Sweden',
  CHE = 'Switzerland',
  SYR = 'Syrian Arab Republic',
  TWN = 'Taiwan',
  TJK = 'Tajikistan',
  TZA = 'Tanzania, United Republic of',
  THA = 'Thailand',
  TLS = 'Timor-Leste',
  TGO = 'Togo',
  TKL = 'Tokelau',
  TON = 'Tonga',
  TTO = 'Trinidad and Tobago',
  TUN = 'Tunisia',
  TUR = 'Turkey',
  TKM = 'Turkmenistan',
  TCA = 'Turks and Caicos Islands (the)',
  TUV = 'Tuvalu',
  UGA = 'Uganda',
  UKR = 'Ukraine',
  ARE = 'United Arab Emirates (the)',
  GBR = 'United Kingdom of Great Britain and Northern Ireland (the)',
  UMI = 'United States Minor Outlying Islands (the)',
  USA = 'United States of America (the)',
  URY = 'Uruguay',
  UZB = 'Uzbekistan',
  VUT = 'Vanuatu',
  VEN = 'Venezuela (Bolivarian Republic of)',
  VNM = 'Viet Nam',
  VGB = 'Virgin Islands (British)',
  VIR = 'Virgin Islands (U.S.)',
  WLF = 'Wallis and Futuna',
  ESH = 'Western Sahara',
  YEM = 'Yemen',
  ZMB = 'Zambia',
  ZWE = 'Zimbabwe',
  ALA = 'Åland Island',
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other',
  RatherNotSay = 'Rather Not Say',
  PleaseSpecify = 'Please Specify',
}

export enum Age {
  UnderTwelve = 'Under 12 years old',
  TwelveToSeventeen = '12-17 years old',
  EighteenToTwentyFour = '18-24 years old',
  TwentyFiveToThirtyFour = '25-34 years old',
  ThirtyFiveToFourtyFour = '35-44 years old',
  FourtyFiveToFiftyFour = '45-54 years old',
  FiftyFiveToSixtyFour = '55-64 years old',
  SixtyFiveToSeventyFour = '65-74 years old',
  OverSeventyFive = '75 years or older',
}

export enum Education {
  None = 'None',
  SomeHighSchool = 'Some high school, no diploma',
  HighSchoolDiploma = 'High school graduate, diploma or the equivalent (for example: GED)',
  SomeCollege = 'Some college credit, no degree',
  TradeSchool = 'Trade/technical/vocational training',
  AssociateDegree = 'Associate degree',
  BachelorsDegree = 'Bachelor’s degree',
  MastersDegree = 'Master’s degree',
  ProfessionalDegree = 'Professional degree',
  DoctorateDegree = 'Doctorate degree',
}

export enum Networth {
  LessThanTenK = '<$10,000',
  TenToHundredK = '$10,000 - $100,000',
  HundredToFiveHundredK = '$100,000 - $500,000',
  FiveHundredToOneM = '$500,000 - $1M',
  OneMToFiveM = '$1M - $5M',
  FiveMToTenM = '$5M - $10M',
  TenMToFiftyM = '$10M - $50M',
  FiftyMToHundredM = '$50M - $100M',
  HundredMToFiveHundredM = '$100M - $500M',
  FiveHundredMToOneB = '$500M - $1B',
  OneBToTenB = '$1B - $10B',
  GreaterThanTenB = '$10B+',
}

export enum Marital {
  Single = 'Single, never married',
  Married = 'Married or domestic partnership',
  Divorced = 'Divorced',
  Widowed = 'Widowed',
  Separated = 'Separated',
  Other = 'Other',
}

export enum Work {
  Architecture = 'Architecture and engineering',
  Arts = 'Arts, culture and entertainment',
  Business = 'Business, management and administration',
  Communications = 'Communications',
  Community = 'Community and social services',
  Education = 'Education',
  Science = 'Science and technology',
  Installation = 'Installation, repair and maintenance',
  Farming = 'Farming, fishing and forestry',
  Government = 'Government',
  Health = 'Health and medicine',
  Law = 'Law and public policy',
  Sales = 'Sales',
  Other = 'Other',
}

export enum Destination {
  Suborbital = 'Suborbital',
  LEOOrbit = 'LEO/Orbit',
  LEOStation = 'LEO/Station',
  CislunarSpace = 'Cislunar Space',
  Moon = 'Moon',
  Mars = 'Mars',
  Ceres = 'Ceres',
  Venus = 'Venus',
  PleaseSpecify = 'Please Specify',
}

export enum Duration {
  MinutesHours = 'Minutes/Hours',
  Days = 'Days',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
  Decade = 'Decade',
  Permanent = 'Permanent',
}

export enum Skillsets {
  MechanicalEngineer = 'Mechanical Engineer',
  ElectricalEngineer = 'Electrical Engineer',
  ChemicalEngineer = 'Chemical Engineer',
  BiologicalEngineer = 'Biological Engineer',
  Farmer = 'Farmer',
  Mechanic = 'Mechanic',
  Plumber = 'Plumber',
  Electrician = 'Electrician',
  Physicist = 'Physicist',
  Botanist = 'Botanist',
  Astronomer = 'Astronomer',
  Artist = 'Artist',
  FilmProducer = 'Film Producer',
  Journalist = 'Journalist',
  ProjectManagement = 'Project Management',
  ProgramManagement = 'Program Management',
  PleaseSpecify = 'Please Specify',
}

export enum Income {
  LessThanTwentyK = '<$20,000',
  TwentyToFiftyK = '$20K - $50K',
  FiftyToHundredK = '$50K - $100K',
  OneHundredToTwoHundredK = '$100K- $200K',
  TwoHundredToFourHundredK = '$200K-$400K',
  GreaterThanFourHundredK = '$400K+',
}

export enum Reason {
  Opportunity = 'Opportunity',
  PoliticalFreedom = 'Political Freedom',
  EnvironmentalSustainability = 'Environmental Sustainability',
  Science = 'Science',
  Exploration = 'Exploration',
  HumanDestiny = 'Human Destiny',
  PleaseSpecify = 'Please Specify',
}

export enum Financing {
  DebtFinancing = 'Debt Financing',
  SaleOfAssets = 'Sale of Assets',
  RevenueShare = 'Revenue Share',
  TransferOfAssets = 'Transfer of Assets',
  Cash = 'Cash',
  PleaseSpecify = 'Please Specify',
}

export interface IProfileData {
  address: string;
  city: string;
  state: string;
  country: string;
  phone: string;
  age: string;
  gender: string;
  genderSpecific: string;
  education: string;
  work: string;
  networth: string;
  race: string;
  ethnicity: string;
  marital: string;
  destination: string;
  destinationSpecific: string;
  duration: string;
  skillsets: string;
  skillsetsSpecific: string;
  income: string;
  reason: string;
  reasonSpecific: string;
  financing: string;
  financingSpecific: string;
  risk: number;
}

export const emptyProfileData = {
  address: '',
  city: '',
  state: '',
  country: '',
  phone: '',
  age: '',
  gender: '',
  genderSpecific: '',
  education: '',
  work: '',
  networth: '',
  race: '',
  ethnicity: '',
  marital: '',
  destination: '',
  destinationSpecific: '',
  duration: '',
  skillsets: '',
  skillsetsSpecific: '',
  income: '',
  reason: '',
  reasonSpecific: '',
  financing: '',
  financingSpecific: '',
  risk: 5,
};

export interface ICountryCountData {
  ISO3: string;
  name?: string;
  count: number;
}

export const testMapData: ICountryCountData[] = [
  {
    "ISO3": "USA",
    "name": "United States",
    "count": 1
  },
];

export interface ICountsData {
  total: number;
  age: number[];
  destination: number[];
  duration: number[];
  education: number[];
  financing: number[];
  networth: number[];
  reason: number[];
  work: number[];
  risk: number[];
  country: ICountryCountData[];
}

export interface INewsItem {
  title: string;
  image: string;
  description: string;
  link: string;
}

export const emptyNewsItem = {
  title: '',
  image: '',
  description: '',
  link: ''
};