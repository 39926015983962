import { Typography } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { ICountsData } from '../helpers';


function BarchartAge({ counts }: { counts: ICountsData }) {
  const data = {
    labels: ['< 12 yr', '12-17 yr', '18-24 yr', '25-34 yr', '35-44 yr', '45-54 yr', '55-64 yr', '65-74 yr', '75+ yr'],
    datasets: [
      {
        data: counts.age,
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(175, 92, 102, 1)',
          'rgba(218, 112, 198, 1)',
          'rgba(55, 19, 6, 1)',
        ],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: 'white',
        }
      },
      y: {
        ticks: {
          color: 'white',
        }
      }
    } 
  };

  return (
    <div className="BarchartAge">
      <Typography>Age by Group</Typography>
      <Bar
        data={data}
        width={300}
        height={300}
        options={options}
      />
    </div>
  );
}

export default BarchartAge;
