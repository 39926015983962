import './App.css';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, getRedirectResult } from './firebase';
import md5 from 'md5';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import {
  getFirestore,
  doc,
  setDoc
  // collection,
  // query,
  // getDocs,
  // where,
  // connectFirestoreEmulator,
} from 'firebase/firestore';
import { assets } from './helpers';
import Header from './components/Header/Header';
import About from './views/About';
import Profile from './views/Profile';
import Login from './views/Login';
import Data from './views/Data';

const theme = createTheme({
  palette: {
    type: 'dark',
  }
});

let preloaded = false;

function App() {
  const db = getFirestore();
  const [loading, error] = useAuthState(auth);


      // Result from Redirect auth flow.
      getRedirectResult(auth).then(async function(result) {
        if (result) {
        // if (result && result.credential) {
        //   // This gives you a Google Access Token. You can use it to access the Google API.
        //   var token = result.credential.accessToken;
        //   document.getElementById('quickstart-oauthtoken').textContent = token;
        // } else {
        //   document.getElementById('quickstart-oauthtoken').textContent = 'null';
        // }
        // The signed-in user info.
          const user = result.user;
          if (user.email) {
            const userId = md5(user.email).toUpperCase();
            // const q = await getDocs(query(usersRef, where('email', '==', user.email)));
            // if (q.docs.length === 0)
            await setDoc(doc(db, 'users', userId), {
              uid: user.uid,
              name: user.displayName,
              authProvider: 'google',
              email: user.email,
            });
          } else {
            throw new Error('Missing user email!');
          }
        }
      }).catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        // var errorMessage = error.message;
        // // The email of the user's account used.
        // var email = error.email;
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        if (errorCode === 'auth/account-exists-with-different-credential') {
          alert('You have already signed up with a different auth provider for that email.');
          // If you are using multiple auth providers on your app you should handle linking
          // the user's accounts here.
        } else {
          console.error(error);
        }
      });


      auth.onAuthStateChanged(function(user) {
        if (user) {
          // User is signed in.
          // var displayName = user.displayName;
          // var email = user.email;
          // var emailVerified = user.emailVerified;
          // var photoURL = user.photoURL;
          // var isAnonymous = user.isAnonymous;
          // var uid = user.uid;
          // var providerData = user.providerData;
        } else {
          console.log("not signed in")
        }
      });


  if (error) {
    console.log(error);
  }

  if (loading) {
    // console.log('Loading...');
  }

  // preload the images exactly once
  if (!preloaded) {
    preloaded = true;
    assets.forEach((url) => {
      const res = document.createElement('link');
      res.rel = 'preload';
      res.as = 'image';
      res.href = url;
      document.head.appendChild(res);
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App-bg"></div>
        <div className="App">
          <header className="App-header">
                <Header />
          </header>
          <div className="App-content">
            <div className="App-container">
              <Switch>
                <Route path="/about">
                  <About />
                </Route>
                <Route path="/profile">
                  <Profile />
                </Route>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/">
                  <Data />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
