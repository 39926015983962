import { Typography } from '@material-ui/core';
import { Pie } from 'react-chartjs-2';
import { ICountsData } from '../helpers';

function PiechartFinancing({ counts }: { counts: ICountsData }) {
  const data = {
    labels: ['Debt Financing', 'Sale of Assets', 'Revenue Share', 'Transfer of Assets', 'Cash', 'Please Specify'],
    datasets: [
      {
        data: counts.financing,
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    
  };

  return (
    <div className="PiechartFinancing">
      <Typography>Financing</Typography>
      <Pie
        data={data}
        width={300}
        height={300}
        options={options}
      />
    </div>
  );
}

export default PiechartFinancing;
