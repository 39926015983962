import { Typography } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { ICountsData } from '../helpers';

function BarchartDuration({ counts }: { counts: ICountsData }) {
  const data = {
    labels: ['Minutes/Hours', 'Days', 'Week', 'Month', 'Year', 'Decade', 'Permanent'],
    datasets: [
      {
        data: counts.duration,
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(175, 92, 102, 1)',
        ],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: 'white',
        }
      },
      y: {
        ticks: {
          color: 'white',
        }
      }
    } 
  };

  return (
    <div className="BarchartDuration">
      <Typography>Duration off the Earth</Typography>
      <Bar
        data={data}
        width={300}
        height={300}
        options={options}
      />
    </div>
  );
}

export default BarchartDuration;
