import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import DataCard from '../components/DataCard/DataCard';
import TotalChart from '../charts/TotalChart';
import BarchartDestination from '../charts/BarchartDestination';
import BarchartAge from '../charts/BarchartAge';
import BarchartEducation from '../charts/BarchartEducation';
import BarchartNetworth from '../charts/BarchartNetworth';
import BarchartDuration from '../charts/BarchartDuration';
import PiechartReason from '../charts/PiechartReason';
import PiechartFinancing from '../charts/PiechartFinancing';
import PiechartRisk from '../charts/PiechartRisk';
import PiechartOccupation from '../charts/PiechartOccupation';
import Mapchart from '../charts/Mapchart';
import NewsHero from '../components/NewsHero/NewsHero';
import { getCounts } from '../firebase';
import { emptyCountsData, ICountsData } from '../helpers';

// Barchart - Total profiles by destination
// Barchart - age by decade
// Barchart - education level
// Barchart - net worth
// Barchart - duration off the earth
// Pie chart - Reason
// Pie chart - Financing
// Pie chart - Risk
// Pie chart - education
// Pie chart - occupation
// Map - Total profiles per country

function Data() {
  const [counts, setCounts] = useState<ICountsData>();

  useEffect(() => {
    if (!counts) {
      getCounts().then((countsData) => {
        setCounts(countsData);
      });
    }
  }, [counts]);

  const data = counts || emptyCountsData;

  return (
    <div className="Data">
      <Grid container spacing={4}>
        <Grid item xs sm={12}>
          <NewsHero />
        </Grid>
        <Grid item xs sm={6} md={4} lg={3} xl={2}>
          <DataCard chart={<TotalChart counts={data} />} />
        </Grid>
        <Grid item xs sm={6} md={4} lg={3} xl={2}>
          <DataCard chart={<BarchartDestination counts={data} />} />
        </Grid>
        <Grid item xs sm={6} md={4} lg={3} xl={2}>
          <DataCard chart={<BarchartAge counts={data} />} />
        </Grid>
        <Grid item xs sm={6} md={4} lg={3} xl={2}>
          <DataCard chart={<BarchartNetworth counts={data} />} />
        </Grid>
        <Grid item xs sm={6} md={4} lg={3} xl={2}>
          <DataCard chart={<BarchartEducation counts={data} />} />
        </Grid>        
        <Grid item xs sm={6} md={4} lg={3} xl={2}>
          <DataCard chart={<BarchartDuration counts={data} />} />
        </Grid>
        <Grid item xs sm={6} md={4} lg={3} xl={2}>
          <DataCard chart={<PiechartReason counts={data} />} />
        </Grid>
        <Grid item xs sm={6} md={4} lg={3} xl={2}>
          <DataCard chart={<PiechartFinancing counts={data} />} />
        </Grid>
        <Grid item xs sm={6} md={4} lg={3} xl={2}>
          <DataCard chart={<PiechartRisk counts={data} />} />
        </Grid>
        <Grid item xs sm={6} md={4} lg={3} xl={2}>
          <DataCard chart={<PiechartOccupation counts={data} />} />
        </Grid>
        <Grid item xs sm={12} md={12} lg={4} xl={4}>
          <DataCard chart={<Mapchart counts={data} />} />
        </Grid>
      </Grid>
    </div>
  );
}

export default Data;
