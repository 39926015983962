import React, { useState, memo } from "react";
import { Typography } from '@material-ui/core';
import { scaleLinear } from 'd3-scale';
import ReactTooltip from "react-tooltip";
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule
} from 'react-simple-maps';
import { ICountsData } from '../helpers';


const geoUrl = 'https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json';
const colorScale = scaleLinear().domain([1, 10]).range(['#D7ECFB', '#36A2EA'] as any);

const MapChart = ({ counts }: { counts: ICountsData }) => {
  const data = counts.country;
  const [tooltipContent, setTooltipContent] = useState("");

  return (
    <div className="Mapchart">
      <Typography>Total profiles per Country</Typography>
      <ComposableMap
          data-tip=""
          projectionConfig={{
            scale: 130,
            rotate: [-11, 0, 0],
          }}
          width={800}
          height={400}
          style={{ width: "auto", height: "100%" }} 
      >
        <Sphere stroke="#E4E5E6" strokeWidth={0.5} id="sphere" fill="#A9A9A9" />
        <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
        {data.length > 0 && (
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const d = data.find((s: any) => s.ISO3 === geo.properties.ISO_A3);
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={d ? `${colorScale(d.count)}` : "#F5F4F6"}
                    onMouseEnter={() => {
                      const { NAME } = geo.properties;
                      console.log(`${NAME} — ${d}`)
                      setTooltipContent(`${NAME} — ${d ? d.count : 0}`);
                    }}
                    onMouseLeave={() => {
                      console.log('onMouseLeave()')
                      setTooltipContent("");
                    }}
                  />
                );
              })
            }
          </Geographies>
        )}
      </ComposableMap>
      <ReactTooltip>{tooltipContent}</ReactTooltip>
    </div>
  );
};

export default memo(MapChart);
